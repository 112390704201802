import { PropsWithChildren, useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";

interface IPortalProps {
  wrapperId: string;
}

export function Portal({
  children,
  wrapperId,
}: PropsWithChildren<IPortalProps>) {
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);

  const createWrapperAndAppendToBody = (elementId: string) => {
    const element = document.createElement("div");
    element.setAttribute("id", elementId);
    document.body.appendChild(element);
    return element;
  };

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId) as HTMLElement;
    let portalCreated = false;
    if (!element) {
      element = createWrapperAndAppendToBody(wrapperId);
      portalCreated = true;
    }

    setPortalElement(element);

    return () => {
      if (portalCreated && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  if (!portalElement) return null;

  return createPortal(children, portalElement);
}
