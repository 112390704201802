import { Breadcrumb as vitalBreadcrumb } from "@hyperlocal/vital";
import styled from "styled-components";
import { BREAKPOINTS } from "../../../../config";

export const Container = styled.div((props) => ({
  display: "flex",
  justifyContent: "center",

  background: props.theme.palette.neutral.lighter,
  overflowX: "hidden",
  height: "100%",

  [BREAKPOINTS.mobile]: {
    background: props.theme.palette.neutral.white,
    maxHeight: "calc(100vh - 56px)",
  },
}));

export const Breadcrumb = styled(vitalBreadcrumb)({
  [BREAKPOINTS.mobile]: {
    display: "none",
  },
});

export const Content = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",

  margin: "0 auto",
  padding: `${props.theme.spacings.inset.lg} ${props.theme.spacings.stack.xl}`,

  width: "100%",
  maxWidth: 1248,

  [BREAKPOINTS.mobile]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: 0,
  },
}));

export const Box = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: props.theme.spacings.inset.lg,

  [BREAKPOINTS.mobile]: {
    gap: "unset",
    overflowY: "hidden",
  },
}));

export const Title = styled.h3((props) => ({
  fontFamily: props.theme.fonts.base,
  fontStyle: "normal",
  fontWeight: props.theme.fontWeights.bold,
  fontSize: props.theme.fontSizes.lg,
  lineHeight: props.theme.lineHeights.default,
  color: props.theme.palette.neutral.darkest,

  [BREAKPOINTS.mobile]: {
    display: "none",
  },

  display: "flex",
  gap: "4px",
  alignItems: "center",
}));
