import styled from "styled-components";
import { BREAKPOINTS } from "../../../../config";

export const Container = styled.div((props) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: props.theme.spacings.stack.x2s,

  position: "relative",

  [BREAKPOINTS.mobile]: {
    marginBottom: "unset",
    overflow: "hidden",
    gridTemplateColumns: "1fr",
  },
}));
