export const transactionStatus = {
  APROVADO: "approved",
  ESTORNADO: "reversed",
  NEGADO: "denied",
  CANCELADO: "canceled",
  DESFEITO: "undone",
  PENDENTE: "pending",
};

export const parseTransactionStatus = (status: string) => {
  return transactionStatus[status] || "approved";
};
