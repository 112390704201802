import Icon, { IconsValuesEnum } from "@hyperlocal/vital-icons";
import { Card } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";
import { IExtension } from "../types";

const EXTENSIONS: Array<IExtension> = [
  {
    name: "Excel",
    extension: "xlsx",
  },
  {
    name: "PDF",
    extension: "pdf",
  },
];

const ICONS: Record<string, IconsValuesEnum> = {
  Excel: "ContentEditExcel",
  PDF: "GeralDocPdf",
};

export function Content({ isExportingData, handleExport, typeExporting }) {
  return (
    <div className="flex flex-col">
      <p className="py-4">Escolha um formato:</p>

      <div className="flex flex-col gap-2">
        {EXTENSIONS.map((file) => (
          <Card
            role="button"
            key={file.name}
            disabled={isExportingData}
            className="flex w-full cursor-pointer content-between items-center text-neutral-black"
            onClick={async () => {
              if (isExportingData) return;
              await handleExport(file.extension);
            }}
          >
            <div className="flex w-full gap-inset-x2s">
              <Icon
                name={ICONS[file.name]}
                fill="currentColor"
                className="size-inset-lg"
              />
              <span>{file.name}</span>
            </div>
            <Icon
              fill="currentColor"
              name={
                isExportingData && typeExporting === file.extension
                  ? "ArrowRefreshLine"
                  : "ArrowNoLineRigh"
              }
              className={twMerge(
                "size-inset-lg",
                isExportingData &&
                  typeExporting === file.extension &&
                  "animate-spin",
              )}
            />
          </Card>
        ))}
      </div>
    </div>
  );
}
