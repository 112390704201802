import * as Popover from "@radix-ui/react-popover";
import styled from "styled-components";

export const Tooltip = styled.div(({ theme }) => ({
  width: "100%",
  padding: theme.spacings.inline.x2s,
  display: "flex",
  background: theme.palette.neutral.lighter,
  borderRadius: theme.borderRadius.sm,
  boxShadow: theme.shadows.full,
  fontFamily: theme.fonts.base,
  fontWeight: theme.fontWeights.regular,
  fontSize: theme.fontSizes.xs,
  color: theme.palette.primary.main,
}));

export const Arrow = styled(Popover.Arrow)(({ theme }) => ({
  fill: theme.palette.neutral.lighter,
}));
