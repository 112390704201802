/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from "react";
import {
  getSalesRoutesData,
  Hooks,
  setHeader,
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Button, Checkbox, Chips, Input, Select } from "@hyperlocal/vital";
import { useNavigate } from "react-router-dom";
import { CARDBRANDS, SALESTATUSES, TRANSACTIONTYPES } from "../../../../config";
import { formatDateToApi } from "../../../../utils";
import { useFilters } from "../../stores";
import * as Styles from "./Filter.styles";

export function Filters() {
  const isMobile = Hooks.useMediaQuery("mobile");
  const navigate = useNavigate();
  const [state, send] = useFilters();

  useEffect(() => {
    if (isMobile) {
      setHeader({
        title: "Filtros",
        leftIcon: {
          name: "SettingTrashClose",
          onClick: () => {
            navigate(getSalesRoutesData().salesReport);
          },
        },
      });
    } else {
      setHeader({
        show: false,
        title: "",
      });
    }
  }, [isMobile, navigate]);

  return (
    <Styles.Container>
      <h4>Por período</h4>
      <Styles.FiltersBody>
        <Styles.ChipsContainer>
          {state.filters.map((filter) => (
            <Chips
              key={filter}
              onClick={() =>
                send({
                  type: "SET_SELECTED_FILTER",
                  payload: { selectedFilter: filter },
                })
              }
              active={state.selectedFilter === filter}
            >
              {filter} dias
            </Chips>
          ))}
        </Styles.ChipsContainer>

        <Styles.FiltersContainer>
          <Input
            type="search"
            placeholder="Buscar..."
            value={state.search}
            onChange={(e) => {
              send({
                type: "SET_SEARCH",
                payload: {
                  search: e.target.value,
                },
              });
            }}
          />
          <div className="customPeriodWrapper">
            <h5>Período personalizado</h5>
            {isMobile && (
              <Button
                type="button"
                variant="link"
                onClick={() => {
                  send({ type: "CLEAR_FILTERS" });
                }}
              >
                Limpar
              </Button>
            )}
          </div>

          <Styles.DateInputsContainer>
            <Input
              type="date"
              label="De"
              value={formatDateToApi(state.startDate)}
              onChange={(e) => {
                send({
                  type: "SET_START_DATE",
                  payload: { startDate: e.target.value },
                });
              }}
              helper={state.dateErrors.startDate ? "error" : "info"}
              helperText={
                state.dateErrors.startDate ? state.dateErrors.startDate : ""
              }
            />
            <Input
              type="date"
              label="Até"
              value={formatDateToApi(state.endDate)}
              onChange={(e) => {
                send({
                  type: "SET_END_DATE",
                  payload: { endDate: e.target.value },
                });
              }}
              helper={state.dateErrors.endDate ? "error" : "info"}
              helperText={
                state.dateErrors.endDate ? state.dateErrors.endDate : ""
              }
            />
          </Styles.DateInputsContainer>
          <h5>Status da venda</h5>
          <Styles.SaleStatusesContainer>
            {SALESTATUSES.map((status) => (
              <Checkbox
                key={status}
                checked={state.selectedSaleStatus.includes(status)}
                onChange={(e) => {
                  send({
                    type: "SET_SELECTED_SALE_STATUS",
                    payload: {
                      selectedSaleStatus: e.checked
                        ? [...state.selectedSaleStatus, status]
                        : state.selectedSaleStatus.filter(
                            (item) => item !== status,
                          ),
                    },
                  });
                }}
              >
                {status}
              </Checkbox>
            ))}
          </Styles.SaleStatusesContainer>
          <h5>Tipo da transação</h5>
          <Select
            value={state.cardBrand}
            onChange={(e) => {
              send({
                type: "SET_CARD_BRAND",
                payload: {
                  cardBrand: e.target.value,
                },
              });
            }}
          >
            <option value="">Todas as Bandeiras</option>
            {CARDBRANDS.map((brand) => (
              <option key={brand} value={brand}>
                {brand}
              </option>
            ))}
          </Select>
          <Styles.TransactionTypesContainer>
            {TRANSACTIONTYPES.map((type) => (
              <Checkbox
                key={type}
                checked={state.selectedTransactionType.includes(type)}
                onChange={(e) => {
                  send({
                    type: "SET_TRANSACTION_TYPE",
                    payload: {
                      selectedTransactionType: e.checked
                        ? [...state.selectedTransactionType, type]
                        : [
                            ...state.selectedTransactionType.filter(
                              (item) => item !== type,
                            ),
                          ],
                    },
                  });
                }}
              >
                {type}
              </Checkbox>
            ))}
          </Styles.TransactionTypesContainer>
        </Styles.FiltersContainer>
      </Styles.FiltersBody>

      <Styles.ButtonContainer>
        {isMobile ? (
          <Button variant="primary" onClick={() => navigate(-1)}>
            Filtrar
          </Button>
        ) : (
          <Button
            variant="link"
            onClick={() => send({ type: "CLEAR_FILTERS" })}
          >
            Limpar filtros
          </Button>
        )}
      </Styles.ButtonContainer>
    </Styles.Container>
  );
}
