import React from "react";
import { Dialog, Sheet } from "@hyperlocal/vital2";

export function MobileView({ children, sheetTrigger, modalTitle }) {
  const dialogRef = React.useRef<React.ElementRef<typeof Dialog.Close>>(null);

  return (
    <Sheet.Root>
      <Sheet.Trigger asChild>{sheetTrigger}</Sheet.Trigger>

      <Sheet.Content side="bottom">
        <Sheet.Header className="border-b-sm pb-inset-md">
          <Sheet.Title>{modalTitle}</Sheet.Title>
          <Dialog.Close ref={dialogRef}></Dialog.Close>
        </Sheet.Header>

        {children}
      </Sheet.Content>
    </Sheet.Root>
  );
}
