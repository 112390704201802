import * as React from "react";

// named imports for React.lazy: https://github.com/facebook/react/issues/14603#issuecomment-726551598
/**
 * This is a TypeScript function that returns a lazily loaded React component from a dynamically
 * imported module.
 * @param factory - A function that returns a Promise that resolves to an object containing the
 * component(s) to be lazily loaded.
 * @param {K} name - The `name` parameter is a key of the interface `I` that represents the component
 * to be lazily imported. It is used to access the specific component from the imported module.
 * @returns The `lazyImport` function returns an object with a single property, which is a lazy-loaded
 * React component. The component is obtained by calling the `factory` function, which returns a
 * promise that resolves to an object with a property named `name`. The `name` argument specifies the
 * name of the property to extract from the resolved object. The `React.lazy` function is used to
 * lazily load
 */
export function lazyImport<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends React.ComponentType<any>,
  I extends { [K2 in keyof I]: T },
  K extends keyof I,
>(factory: () => Promise<I>, name: K): I {
  return Object.create({
    [name]: React.lazy(() =>
      factory().then((module) => ({ default: module[name] })),
    ),
  });
}
