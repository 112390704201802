import {
  Avatar as VitalAvatar,
  Divider as VitalDivider,
} from "@hyperlocal/vital";
import styled from "styled-components";
import { IAvatarProps, ITransactionStatus } from "./types";

export const Container = styled.div({
  width: 480,
  height: 930,
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  "@media (max-width: 480px)": {
    width: "100%",
    height: "100%",
  },
});

export const DetailsContainer = styled.div((props) => ({
  width: "100%",
  height: 700,

  margin: "0 auto",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  fontFamily: props.theme.fonts.base,
  backgroundColor: props.theme.palette.neutral.white,

  "@media (max-width: 480px)": {
    width: "100%",
    height: "100%",
  },
}));

export const DetailsHeader = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  marginTop: props.theme.spacings.stack.xs,
}));

export const Title = styled.h3((props) => ({
  fontFamily: props.theme.fonts.base,
  fontSize: props.theme.fontSizes.sm,
  fontWeight: props.theme.fontWeights.bold,
  textAlign: "center",
  color: props.theme.palette.neutral.darkest,
}));

export const Subtitle = styled(Title)<{
  variant?: ITransactionStatus;
}>((props) => {
  const styles = {
    success: {
      color: props.theme.palette.secondary.darker,
    },
    error: {
      color: props.theme.palette.status.error.errorDark,
    },
    warning: {
      color: props.theme.palette.status.warning.warningDark,
    },
    default: {
      color: props.theme.palette.neutral.darkest,
    },
  };

  const variants = {
    APROVADO: { ...styles.success },
    NEGADO: { ...styles.error },
    ESTORNADO: { ...styles.error },
    CANCELADO: { ...styles.error },
    PENDENTE: { ...styles.warning },
    default: { ...styles.default },
  };

  return {
    ...(variants[props.variant] || variants.default),
  };
});

export const Avatar = styled(VitalAvatar)<IAvatarProps>((props) => {
  const styles = {
    default: {
      backgroundColor: props.theme.palette.primary.lighter,
    },
    success: {
      "& svg > path": {
        fill: props.theme.palette.secondary.darker,
      },
      "& svg > g": {
        stroke: props.theme.palette.secondary.darker,
      },
      backgroundColor: props.theme.palette.secondary.lighter,
    },
    error: {
      backgroundColor: props.theme.palette.status.error.errorLight,
      "& svg > path": {
        fill: props.theme.palette.status.error.errorDark,
      },
      "& svg > g": {
        stroke: props.theme.palette.status.error.errorDark,
      },
    },
    warning: {
      backgroundColor: props.theme.palette.status.warning.warningLight,
      "& svg > path": {
        fill: props.theme.palette.status.warning.warningDark,
      },
      "& svg > g": {
        stroke: props.theme.palette.status.warning.warningDark,
      },
    },
  };

  const variants = {
    APROVADO: { ...styles.success },
    NEGADO: { ...styles.error },
    ESTORNADO: { ...styles.error },
    CANCELADO: { ...styles.error },
    PENDENTE: { ...styles.warning },
    default: { ...styles.default },
  };

  const selectedVariant = Object.entries(variants).reduce(
    (item: Record<string, object>, [key, value]) => {
      item[key] = value;
      return item;
    },
    {},
  );

  return {
    ...(selectedVariant[props.subVariant] ?? selectedVariant.default),
    marginBottom: props.theme.spacings.stack.x2s,
  };
});

export const Divider = styled(VitalDivider)((props) => ({
  marginTop: props.theme.spacings.stack.x2s,
  marginBottom: props.theme.spacings.stack.x2s,

  width: "338.35px",
  backgroundColor: props.theme.palette.neutral.light,
}));

export const DetailsBodyRow = styled.div((props) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",

  backgroundColor: "transparent",

  minWidth: 343,
  minHeight: 24,

  marginBottom: props.theme.spacings.stack.nano,

  "& p:first-of-type": {
    fontFamily: props.theme.fonts.base,
    fontSize: props.theme.fontSizes.x2s,
    fontWeight: props.theme.fontWeights.medium,
    color: props.theme.palette.neutral.darkest,
  },

  "& p:last-of-type": {
    fontFamily: props.theme.fonts.base,
    fontSize: props.theme.fontSizes.x2s,
    fontWeight: props.theme.fontWeights.regular,
    color: props.theme.palette.neutral.dark,
  },
}));

export const DisplayCardFlag = styled.p((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& svg": {
    width: 24,
    height: 24,
    "&.PIX": {
      stroke: props.theme.palette.secondary.dark,
    },
  },
}));

export const DetailsFooter = styled.div((props) => ({
  marginLeft: `-${props.theme.spacings.stack.md}`,
  "& p:first-of-type": {
    fontSize: props.theme.fontSizes.x2s,
    color: props.theme.palette.neutral.darkest,
    fontWeight: props.theme.fontWeights.medium,

    marginBottom: props.theme.spacings.stack.quarck,
  },

  "& p:last-of-type": {
    fontSize: props.theme.fontSizes.x2s,
    color: props.theme.palette.neutral.dark,
    fontWeight: props.theme.fontWeights.regular,
  },
}));
