import { useEffect } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getSalesRoutesData, setHeader } from "@hyperlocal/banking-utility";
import Icon from "@hyperlocal/vital-icons";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ISalesListItem } from "../../../../services/types";
import {
  capitalizeFirstLetter,
  capitalizeOperation,
  cardBrandIcon,
  formatDateSearch,
  formatPlataform,
  formatToReal,
  formatTransactionType,
  iconName,
  truncateCardNumber,
} from "../../../../utils";
import {
  Avatar,
  Container,
  DetailsBodyRow,
  DetailsContainer,
  DetailsFooter,
  DetailsHeader,
  DisplayCardFlag,
  Divider,
  Subtitle,
  Title,
} from "./Details.styles";
import { ITransactionStatus } from "./types";

export function Details() {
  const location = useLocation();
  const state = location.state as { transaction: ISalesListItem };
  const navigate = useNavigate();

  function handleHeader() {
    setHeader({
      show: true,
      title: "Detalhes da Venda",
      leftIcon: {
        name: "SettingTrashClose",
        onClick() {
          navigate(getSalesRoutesData().salesReportDetails);
        },
      },
    });
  }

  useEffect(() => {
    handleHeader();
    return () => {
      setHeader({
        show: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state?.transaction ? (
    <Container>
      <DetailsContainer>
        <DetailsHeader>
          <Avatar
            icon={iconName(state.transaction?.transaction_status)}
            variant="primary"
            subVariant={
              state.transaction?.transaction_status as ITransactionStatus
            }
            size="lg"
          />
          <Title>
            {capitalizeOperation(
              state.transaction?.transaction_type,
              state.transaction?.credit_card_company,
              "",
            )}
          </Title>
          <Subtitle
            variant={
              state.transaction?.transaction_status as ITransactionStatus
            }
          >
            {capitalizeFirstLetter(
              state.transaction?.transaction_status.toLowerCase(),
            )}
          </Subtitle>
        </DetailsHeader>
        <Divider />
        <div>
          <DetailsBodyRow>
            <p>Valor da venda</p>
            <p>{formatToReal(state.transaction?.total_value)}</p>
          </DetailsBodyRow>
          <DetailsBodyRow>
            <p>Data da transação</p>
            <p>{formatDateSearch(state.transaction?.transaction_date)}</p>
          </DetailsBodyRow>
          <DetailsBodyRow>
            <p>Tipo da transação</p>
            <p>{formatTransactionType(state.transaction?.transaction_type)}</p>
          </DetailsBodyRow>
          <DetailsBodyRow>
            <p>Nº de parcelas</p>
            <p>
              {state.transaction.total_installments === 0 ||
              state.transaction.total_installments === 1
                ? "à vista"
                : `x${state.transaction.total_installments}`}
            </p>
          </DetailsBodyRow>
          <DetailsBodyRow>
            <p>Método de pagamento</p>
            <p>
              {capitalizeOperation(
                state.transaction?.transaction_type,
                state.transaction?.credit_card_company,
                "",
              )}
            </p>
          </DetailsBodyRow>
          <DetailsBodyRow>
            <p>Bandeira</p>
            <DisplayCardFlag>
              <Icon
                className={state.transaction?.credit_card_company}
                name={cardBrandIcon(state.transaction?.credit_card_company)}
              />
            </DisplayCardFlag>
          </DetailsBodyRow>
          <DetailsBodyRow>
            <p>Nº do cartão</p>
            <p>{`****${truncateCardNumber(
              state.transaction?.truncated_number,
            )}`}</p>
          </DetailsBodyRow>
          <DetailsBodyRow>
            <p>Captura</p>
            <p>{formatPlataform(state.transaction?.platform)}</p>
          </DetailsBodyRow>
          <DetailsBodyRow>
            <p>Nº serial do terminal</p>
            <p>{state.transaction?.terminal_serial_number}</p>
          </DetailsBodyRow>
          <DetailsBodyRow>
            <p>Cod de autorização</p>
            <p>{state.transaction?.authorization_code}</p>
          </DetailsBodyRow>
          <DetailsBodyRow>
            <p>NSU</p>
            <p>{state.transaction?.nsu}</p>
          </DetailsBodyRow>
        </div>
        <Divider />
        <DetailsFooter>
          <p>Id da operação</p>
          <p>{state.transaction?.guid_transaction}</p>
        </DetailsFooter>
      </DetailsContainer>
    </Container>
  ) : (
    <Navigate to={getSalesRoutesData().salesReport} />
  );
}
