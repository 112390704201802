// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { formatCurrency } from "@hyperlocal/banking-utility";
import Icon from "@hyperlocal/vital-icons";
import { Avatar, Sheet } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";
import { tv } from "tailwind-variants";
import * as utils from "../../../../utils";
import { parseTransactionStatus } from "../../../../utils/general";
import { ISalesListItem } from "../../api";

export interface IDetailsDrawerProps {
  content: ISalesListItem;
  isOpen: boolean;
  onClose: () => void;
}

const generalStyles = tv({
  slots: {
    dl: "flex justify-between my-4 gap-4 w-full flex-wrap",
    dt: "font-base font-medium text-neutral-darkest text-xs/default w-fit",
    dd: "font-base text-neutral-dark text-xs/default text-end",
  },
});

const avatarStyles = tv({
  variants: {
    status: {
      approved: "bg-secondary-lighter text-secondary-lighter",
      pending: "bg-status-warning-light text-status-warning-light",
      reversed: "bg-status-error-light text-status-error-light",
      canceled: "bg-status-error-light text-status-error-light",
      undone: "bg-status-error-light text-status-error-light",
      denied: "bg-status-error-light text-status-error-light",
    },
  },
});

const avatarIconStyles = tv({
  variants: {
    status: {
      approved: "fill-secondary-darker stroke-secondary-darker",
      pending: "fill-status-warning-darker stroke-status-warning-darker",
      reversed: "fill-status-error-darker stroke-status-error-darker",
      canceled: "fill-status-error-darker stroke-status-error-darker",
      undone: "fill-status-error-darker stroke-status-error-darker",
      denied: "fill-status-error-darker stroke-status-error-darker",
    },
  },
});

const subTitleStyles = tv({
  base: "text-neutral-darkest",
  variants: {
    status: {
      approved: "text-secondary-darker",
      pending: "text-status-warning-darker",
      reversed: "text-status-error-darker",
      canceled: "text-status-error-darker",
      undone: "text-status-error-darker",
      denied: "text-status-error-darker",
    },
  },
});

export function DetailsDrawer({
  isOpen,
  onClose,
  content: transaction,
}: IDetailsDrawerProps) {
  const { dd, dl, dt } = generalStyles();

  function dateFormat(date: string | Date) {
    if (!date) return;
    const dateFormatted = new Date(date);
    return dateFormatted.toLocaleDateString("pt-BR", { timeZone: "UTC" });
  }

  if (!transaction) return null;

  return (
    <Sheet.Root open={isOpen} onOpenChange={onClose}>
      <Sheet.Content>
        <Sheet.Header>
          <Sheet.Title>Detalhes da venda</Sheet.Title>
        </Sheet.Header>
        <div className="mb-6 flex w-full flex-col items-center">
          <Avatar.Root
            variant="primary"
            size="lg"
            className={twMerge(
              "flex-shrink-0",
              avatarStyles({
                status: parseTransactionStatus(transaction.transaction_status),
              }),
            )}
          >
            <Avatar.Slot
              name={utils.iconName(transaction.transaction_status)}
              fill="currentColor"
              className={avatarIconStyles({
                status: parseTransactionStatus(transaction.transaction_status),
              })}
            />
          </Avatar.Root>
          <h3 className="mt-6 text-center text-sm font-bold text-neutral-darkest">
            {utils.capitalizeOperation(
              transaction?.transaction_type,
              transaction?.credit_card_company,
              transaction?.transaction_status,
            )}
          </h3>
          <h3
            className={twMerge(
              "text-center text-sm font-bold text-neutral-darkest",
              subTitleStyles({
                status: parseTransactionStatus(transaction.transaction_status),
              }),
            )}
          >
            {utils.capitalizeFirstLetter(
              transaction?.transaction_status.toLowerCase(),
            )}
          </h3>
          <hr className="my-4 w-full" />
          <dl className={dl()}>
            <dt className={dt()}>Valor da venda</dt>
            <dd className={dd()}>{formatCurrency(transaction?.total_value)}</dd>
          </dl>
          <dl className={dl()}>
            <dt className={dt()}>Data da transação</dt>
            <dd className={dd()}>
              {dateFormat(transaction?.transaction_date)}
            </dd>
          </dl>
          <dl className={dl()}>
            <dt className={dt()}>Tipo da transação</dt>
            <dd className={dd()}>
              {utils.formatTransactionType(transaction?.transaction_type)}
            </dd>
          </dl>
          <dl className={dl()}>
            <dt className={dt()}>Método de pagamento</dt>
            <dd className={dd()}>
              {utils.capitalizeOperation(
                transaction?.transaction_type,
                transaction?.credit_card_company,
                transaction?.transaction_status,
              )}
            </dd>
          </dl>
          <dl className={dl()}>
            <dt className={dt()}>Bandeira</dt>
            <div className="flex items-center justify-center">
              <Icon
                height={24}
                width={24}
                name={utils.cardBrandIcon(transaction?.credit_card_company)}
              />
            </div>
          </dl>
          <dl className={dl()}>
            <dt className={dt()}>Nº do cartão</dt>
            <dd className={dd()}>{transaction?.truncated_number}</dd>
          </dl>
          <dl className={dl()}>
            <dt className={dt()}>Captura</dt>
            <dd className={dd()}>
              {utils.formatPlataform(transaction?.platform)}
            </dd>
          </dl>
          <dl className={dl()}>
            <dt className={dt()}>Nº serial do terminal</dt>
            <dd className={dd()}>{transaction?.terminal_serial_number}</dd>
          </dl>
          <dl className={dl()}>
            <dt className={dt()}>Cod de autorização</dt>
            <dd className={dd()}>{transaction?.authorization_code}</dd>
          </dl>
          <dl className={dl()}>
            <dt className={dt()}>NSU</dt>
            <dd className={dd()}>{transaction?.nsu}</dd>
          </dl>
          <hr className="w-full" />
          <div className={twMerge(dl(), "flex-col items-start")}>
            <dt className={twMerge(dt(), "text-start")}>Id da operação</dt>
            <dd className={twMerge(dd(), "text-start")}>
              {transaction?.guid_transaction}
            </dd>
          </div>
        </div>
      </Sheet.Content>
    </Sheet.Root>
  );
}
