import { useCallback, useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IUseDisclosureProps<T extends Record<string, any>> {
  isOpen: boolean;
  open: (props?: T) => void;
  close: () => void;
  toggle: () => void;
  modalProps?: T;
}

export function useDisclosure<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any>,
>(): IUseDisclosureProps<T> {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState<T>({} as T);

  const open = useCallback((props?: T) => {
    setIsOpen(true);
    setModalProps(props || ({} as T));
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
    setModalProps({} as T);
  }, []);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
    setModalProps({} as T);
  }, [isOpen]);

  return { isOpen, open, close, toggle, modalProps };
}
