import {
  DefaultOptions,
  QueryCache,
  QueryClient,
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry: false,
    suspense: true,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });
export const queryCache = new QueryCache();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IQueryConfig<FetcherFnType extends (...args: any) => any> =
  UseQueryOptions<Awaited<ReturnType<FetcherFnType>>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IMutationConfig<FetcherFnType extends (...args: any) => any> =
  UseMutationOptions<
    Awaited<ReturnType<FetcherFnType>>,
    AxiosError,
    Parameters<FetcherFnType>[0]
  >;
