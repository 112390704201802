import { createGlobalStyle, css } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    ${({ theme }) => css`
      body {
        font-family: ${theme.fonts.base};
        background: ${theme.palette.neutral.lighter};
        color: ${theme.palette.neutral.dark};

        @media (max-width: 720px) {
          background: ${theme.palette.neutral.white};
        }
      }
    `};
`;
