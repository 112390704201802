import { Chips, Avatar as VitalAvatar } from "@hyperlocal/vital";
import styled from "styled-components";
import { BREAKPOINTS } from "../../../../config";
import { ICardTypes } from "./Card";

export const Root = styled.div({
  width: "100%",
});

export const Container = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: props.theme.spacings.inline.sm,

  [BREAKPOINTS.mobile]: {
    width: "100%",
    maxWidth: "100vw",
    gap: props.theme.spacings.inline.xs,
    overflowY: "hidden",
  },

  [BREAKPOINTS.desktop]: {
    position: "absolute",
    inset: "0",
    gridColumn: "2",
  },
}));

export const CardsWrapper = styled.div((props) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: props.theme.spacings.inline.xs,
  minWidth: "max-content",

  [BREAKPOINTS.mobile]: {
    gap: props.theme.spacings.inline.x2s,
  },
}));

export const CardsContainer = styled.section((props) => ({
  width: "100%",

  [BREAKPOINTS.mobile]: {
    overflowX: "auto",
    scrollbarWidth: "none",
    whiteSpace: "nowrap",
    flexShrink: 0,
    padding: `0 ${props.theme.spacings.inline.xs}`,
    scrollSnapType: "x mandatory",

    "::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

export const Card = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: props.theme.palette.neutral.white,

  width: "100%",
  height: "auto",

  padding: `${props.theme.spacings.inset.md}`,
  gap: props.theme.spacings.inset.x3s,

  borderRadius: props.theme.borderRadius.sm,
  scrollSnapAlign: "center",

  "& div:has(svg)": {
    cursor: "initial",
  },

  "& div:first-of-type": {
    display: "flex",
    alignItems: "center",
    gap: props.theme.spacings.inline.x2s,

    "& > p": {
      fontSize: props.theme.fontSizes.xs,
      fontWeight: props.theme.fontWeights.medium,
      lineHeight: props.theme.lineHeights.sm,
    },
  },

  "& div:last-of-type": {
    display: "flex",
    alignItems: "center",
    gap: props.theme.spacings.inline.x3s,

    "& > h6": {
      fontSize: props.theme.fontSizes.xs,
      fontWeight: props.theme.fontWeights.bold,
      lineHeight: props.theme.lineHeights.md,
      color: props.theme.palette.neutral.dark,
    },

    "& > span": {
      fontSize: props.theme.fontSizes.x2s,
      fontWeight: props.theme.fontWeights.bold,
      lineHeight: props.theme.lineHeights.md,
      color: props.theme.palette.neutral.dark,
      [BREAKPOINTS.mobile]: {
        fontSize: props.theme.fontSizes.x4s,
      },
    },
  },

  [BREAKPOINTS.mobile]: {
    border: `1px solid ${props.theme.palette.neutral.light}`,
  },
}));

export const Avatar = styled(VitalAvatar)<{
  type: ICardTypes;
}>((props) => {
  const variants = {
    approved: {
      backgroundColor: props.theme.palette.secondary.lighter,
      "& svg": {
        fill: props.theme.palette.secondary.darker,
        stroke: props.theme.palette.secondary.darker,
      },
    },
    pending: {
      backgroundColor: props.theme.palette.status.warning.warningLight,
      "& svg": {
        fill: props.theme.palette.status.warning.warningDark,
        stroke: props.theme.palette.status.warning.warningDark,
      },
    },
    reversed: {
      backgroundColor: props.theme.palette.status.error.errorLight,
      "& svg": {
        fill: props.theme.palette.status.error.errorDark,
        stroke: props.theme.palette.status.error.errorDark,
      },
    },
    canceled: {
      backgroundColor: props.theme.palette.status.error.errorLight,
      "& svg": {
        fill: props.theme.palette.status.error.errorDark,
        stroke: props.theme.palette.status.error.errorDark,
      },
    },
    undone: {
      backgroundColor: props.theme.palette.status.error.errorLight,
      "& svg": {
        fill: props.theme.palette.status.error.errorDark,
        stroke: props.theme.palette.status.error.errorDark,
      },
    },
    denied: {
      backgroundColor: props.theme.palette.status.error.errorLight,
      "& svg": {
        fill: props.theme.palette.status.error.errorDark,
        stroke: props.theme.palette.status.error.errorDark,
      },
    },
  };

  return {
    ...variants[props.type],
    width: 32,
    height: 32,
  };
});

export const Content = styled.section((props) => ({
  padding: props.theme.spacings.inset.lg,

  background: props.theme.palette.neutral.white,

  border: `1px solid ${props.theme.palette.neutral.white}`,
  borderRadius: props.theme.borderRadius.sm,

  overflow: "hidden",
  height: "100%",

  [BREAKPOINTS.mobile]: {
    padding: `0 ${props.theme.spacings.inline.xs}`,
    width: "100%",
  },
}));

export const ListContainer = styled.div((props) => ({
  width: "100%",
  height: "100%",

  display: "flex",
  flexDirection: "column",

  background: props.theme.palette.neutral.white,

  "& input": {
    display: "none",
    boxSizing: "content-box",

    "&[data-visible='true']": {
      display: "block",
    },
    "&[data-visible='false']": {
      display: "none",
    },
  },
}));

export const FiltersRow = styled.div((props) => ({
  display: "none",

  "div:last-of-type": {
    justifyContent: "flex-end",
    "& > button": {
      outline: "none",
      backgroundColor: "transparent",
      border: "none",
      "& svg": {
        maxWidth: "100%",
        fill: props.theme.palette.neutral.dark,
        minWidth: 24,
      },
    },
  },

  [BREAKPOINTS.mobile]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& > button:last-of-type": {
      outline: "none",
      backgroundColor: "transparent",
      border: "none",
      "& svg": {
        maxWidth: "100%",
        fill: props.theme.palette.neutral.dark,
        minWidth: 24,
      },
    },

    "& div": {
      width: "100%",

      display: "flex",
      alignItems: "center",
      gap: props.theme.spacings.inline.xs,
    },
  },
}));

export const SelectedFilterButton = styled(Chips)({
  "&[data-visible='true']": {
    visibility: "visible",
    opacity: 1,
  },

  "&[data-visible='false']": {
    visibility: "hidden",
    opacity: 0,
  },

  [BREAKPOINTS.mobile]: {
    width: "max-content",
  },
});

export const List = styled.ul((props) => ({
  width: "100%",
  height: "100%",

  overflowX: "hidden",

  "li + li": {
    marginTop: props.theme.spacings.stack.x3s,
  },
}));

export const ListHeadings = styled.div((props) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",

  marginBottom: props.theme.spacings.stack.x3s,
  marginTop: props.theme.spacings.stack.x3s,

  h5: {
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.bold,
    fontSize: props.theme.fontSizes.sm,
    lineHeight: props.theme.lineHeights.md,
    color: props.theme.palette.neutral.darkest,
  },

  p: {
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.regular,
    fontSize: props.theme.fontSizes.xs,
    lineHeight: props.theme.lineHeights.sm,
    color: props.theme.palette.neutral.dark,
  },

  [BREAKPOINTS.mobile]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 4,
    h5: {
      fontSize: props.theme.fontSizes.xs,
    },

    p: {
      fontWeight: props.theme.fontWeights.medium,
      fontSize: props.theme.fontSizes.x3s,
    },
  },
}));

export const ListItem = styled.li((props) => ({
  listStyleType: "none",
  "& button": {
    cursor: "pointer",

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",

    padding: "12px 24px",

    width: "100%",
    height: 73,

    border: `1px solid ${props.theme.palette.neutral.lighter}`,
    borderRadius: props.theme.borderRadius.sm,
    "&:hover": {
      border: `1px solid ${props.theme.palette.primary.main}`,
      transition: "border-color 300ms linear 0s",
    },
    [BREAKPOINTS.mobile]: {
      height: 63,
    },
  },
}));

export const ListItemInfo = styled.div((props) => ({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",

  "& div": {
    width: "100%",
    height: "100%",
  },

  "& div:first-of-type": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    "& div": {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "flex-start",
      gap: 4,
      flexDirection: "row",
      margin: 0,
    },
    "& svg": {
      width: 24,
      height: 24,
      fill: props.theme.palette.primary.dark,
    },

    "& p:nth-of-type(1)": {
      textAlign: "left",
      width: "10em",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontFamily: props.theme.fonts.base,
      fontWeight: props.theme.fontWeights.regular,
      fontSize: props.theme.fontSizes.x2s,
      lineHeight: props.theme.lineHeights.md,
      color: props.theme.palette.neutral.darkest,
    },
    "& p:nth-of-type(2)": {
      fontFamily: props.theme.fonts.base,
      fontWeight: props.theme.fontWeights.regular,
      fontSize: props.theme.fontSizes.xs,
      lineHeight: props.theme.lineHeights.md,
      color: props.theme.palette.neutral.dark,
      // [EBreakPoints.mobile]: {
      //   fontSize: props.theme.fontSizes.x2s,
      // },
    },
  },

  "& div:nth-of-type(2)": {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    "& p:nth-of-type(1)": {
      fontFamily: props.theme.fonts.base,
      fontWeight: props.theme.fontWeights.regular,
      fontSize: props.theme.fontSizes.x2s,
      lineHeight: props.theme.lineHeights.md,
      color: props.theme.palette.neutral.dark,
      // [EBreakPoints.mobile]: {
      //   fontSize: 10,
      // },
    },
    "& p:nth-of-type(2)": {
      fontFamily: props.theme.fonts.base,
      fontWeight: props.theme.fontWeights.regular,
      fontSize: props.theme.fontSizes.xs,
      lineHeight: props.theme.lineHeights.sm,
      color: props.theme.palette.secondary.darker,
      // [EBreakPoints.mobile]: {
      //   fontSize: props.theme.fontSizes.xs,
      // },
    },
  },

  "div:has(svg)": {
    display: "flex",
    alignItems: "center",
    width: 24,
    height: 24,
    marginLeft: 16,
  },
}));

export const ListItemContent = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

export const Button = styled(Chips)((props) => ({
  width: 106,

  marginTop: `${props.theme.spacings.stack.nano}`,
  marginBottom: `${props.theme.spacings.stack.nano}`,
}));

export const SaleNotFound = styled.div((props) => ({
  textAlign: "center",
  margin: props.theme.spacings.inline.x2s,

  "& p": {
    fontWeight: props.theme.fontWeights.medium,
    fontSize: props.theme.fontSizes.x3s,
    color: props.theme.palette.neutral.main,
  },
}));
