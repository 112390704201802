import { HtmlHTMLAttributes } from "react";
import { Skeleton } from "@hyperlocal/vital";
import { v4 } from "uuid";
import * as Styles from "./Display.styles";
import { SkeletonItem } from "./SkeletonItem";

type SkeletonDisplayProps = HtmlHTMLAttributes<HTMLDivElement>;

export function SkeletonDisplay(props: SkeletonDisplayProps) {
  return (
    <Styles.Container {...props}>
      <Styles.CardsContainer>
        <Styles.CardsWrapper>
          {Array.from({ length: 3 }).map((_, index) => (
            <Styles.Card key={index}>
              <div style={{ width: "100%" }}>
                <Skeleton width="32px" height="32px" borderRadius="100%" />
                <Skeleton width="80%" height="16px" />
              </div>
              <div>
                <Skeleton width="100%" />
              </div>
            </Styles.Card>
          ))}
        </Styles.CardsWrapper>
      </Styles.CardsContainer>

      <Styles.Content>
        <Styles.ListItemContent>
          <Styles.List>
            <Styles.ListHeadings>
              <h5>
                <Skeleton width="225px" height="16px" />
              </h5>
              <p>
                <Skeleton width="225px" height="16px" />
              </p>
            </Styles.ListHeadings>

            {Array.from({ length: 6 }).map(() => (
              <SkeletonItem key={v4()} />
            ))}
          </Styles.List>
        </Styles.ListItemContent>
      </Styles.Content>
    </Styles.Container>
  );
}
