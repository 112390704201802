import React from "react";
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { Hooks } from "@hyperlocal/banking-utility";
import { formatToReal } from "../../../../utils";
import { useDownloadDocument } from "../../api";
import { Content } from "./Content";
import { DestkopView } from "./DesktopView";
import { MobileView } from "./MobileView";
import { IExportFilesModalProps, IExtension } from "./types";

export function ExportFilesModal({
  data,
  onClose,
  children,
  modalTitle,
}: IExportFilesModalProps) {
  const isMobile = Hooks.useMediaQuery("mobile");
  const mutation = useDownloadDocument();

  const [exportingType, setExportingType] = React.useState<
    null | IExtension["extension"]
  >(null);
  const [isExportingData, setIsExportingData] = React.useState(false);

  async function handleExport(extension: IExtension["extension"]) {
    setExportingType(extension);
    setIsExportingData(true);

    const body = data?.flatMap((item) => {
      return item.transactions.map((transaction) => {
        return {
          Data: transaction.transaction_date,
          "Valor bruto(R$)": formatToReal(transaction.total_value),
          Tipo: transaction.transaction_type,
          Parcelas: transaction.total_installments,
          Status: transaction.transaction_status,
          Bandeira: transaction.credit_card_company,
          "Nº do cartão": transaction.truncated_number,
          NSU: transaction.nsu,
          "COD. autorização": transaction.authorization_code,
          "Tipo de captura": transaction.platform,
          Terminal: transaction.terminal_serial_number,
        };
      });
    });

    await mutation.mutateAsync({
      extension,
      body,
    });

    setExportingType(null);
    setIsExportingData(false);

    onClose?.();
  }

  return isMobile ? (
    <MobileView modalTitle={modalTitle} sheetTrigger={children}>
      <Content
        handleExport={handleExport}
        typeExporting={exportingType}
        isExportingData={isExportingData}
      />
    </MobileView>
  ) : (
      <DestkopView modalTitle={modalTitle} isExportingData={isExportingData}>
        <Content
          handleExport={handleExport}
          typeExporting={exportingType}
          isExportingData={isExportingData}
        />
      </DestkopView>
  );
}
