import { useEffect, useState } from "react";
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { toast, useAccountStore } from "@hyperlocal/banking-utility";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../../../lib/axios";
import { formatDateToApi } from "../../../../utils";
import { useFilters } from "../../stores";
import {
  IGetSalesList,
  ISalesList,
  ISalesListParams,
  ITransaction,
  IUseSalesProps,
} from "./types";

export const transactionStatus = {
  APROVADO: "approved",
  ESTORNADO: "reversed",
  NEGADO: "denied",
  CANCELADO: "canceled",
  DESFEITO: "undone",
  PENDENTE: "pending",
};

export async function getSalesList({
  guidAccount,
  startDate,
  endDate,
  guidCreditCardCompany,
  transactionType,
  status,
}: ISalesListParams): Promise<IGetSalesList> {
  try {
    const response = await api.get<ISalesList>(`sales/${guidAccount}/list`, {
      params: {
        startDate,
        endDate,
        guidCreditCardCompany,
        transactionType,
        status,
        timeout: 600000,
      },
    });
    return {
      aprovadas: response.data.aprovadas,
      estornadas: response.data.estornadas,
      pendentes: response.data.pendentes,
      transacoes: formatTransactions(response.data),
    };
  } catch (error) {
    toast.error("Houve um erro ao buscar as vendas.");
    return null;
  }
}

export function querySalesList({
  guidAccount,
  startDate,
  endDate,
  guidCreditCardCompany,
  transactionType,
  status,
}: ISalesListParams) {
  return {
    queryKey: [
      "sales",
      startDate,
      endDate,
      guidAccount,
      transactionType,
      status,
    ],
    queryFn: async () => {
      return await getSalesList({
        guidAccount,
        startDate,
        endDate,
        guidCreditCardCompany,
        transactionType,
        status,
      });
    },
  };
}

function formatTransactions(sales: ISalesList): ITransaction[] {
  const transactions = sales.transacoes.reduce((acc, transaction) => {
    const date = transaction.transaction_date.split("T")[0];
    const transactionType = formatTransactionType(
      transaction.transaction_type,
      transaction.credit_card_company,
    );
    const amount =
      transaction.transaction_status === "ESTORNADO"
        ? transaction.total_value * -1
        : transaction.total_value;

    const key = `${date}_${transactionType}`;
    if (acc[key]) {
      acc[key].amount += amount;
      acc[key].transactions.push(transaction);
    } else {
      acc[key] = { date, amount, transactions: [transaction] };
    }
    return acc;
  }, {} as { [key: string]: ITransaction });

  return Object.values(transactions);
}

function formatTransactionType(
  transactionType: string,
  creditCardCompany: string,
) {
  switch (transactionType) {
    case "PARCELAMENTO SEM JUROS":
    case "CREDITO A VISTA":
      return "Crédito";
    case "DEBITO":
      return creditCardCompany === "PIX" ? "Pix" : "Débito";
  }
}

export function formatTransactionStatus(transactionStatus: string) {
  const mapTransactionStatus = {
    APROVADO: "Aprovadas",
    ESTORNADO: "Estornadas",
    PENDENTE: "Pendentes",
    NEGADO: "Negadas",
    CANCELADO: "Canceladas",
    DESFEITO: "Desfeitas",
  };
  return mapTransactionStatus[transactionStatus] || "";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filterTransactions = (transactions: ITransaction[], state: any) => {
  const filteredTransactions = transactions?.map((transaction) => {
    const filtered = transaction.transactions.filter((t) => {
      const transactionType = state.selectedTransactionType.includes(
        formatTransactionType(t.transaction_type, t.credit_card_company),
      );

      const saleStatus = state.selectedSaleStatus.includes(
        formatTransactionStatus(t.transaction_status),
      );

      const cardBrand = state.cardBrand
        ? t.credit_card_company.toLowerCase() === state.cardBrand.toLowerCase()
        : true;

      const search = Object.values(t).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(state.search?.toLowerCase());
        }

        if (typeof value === "number") {
          return value
            .toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
            .includes(state.search);
        }

        return false;
      });

      return search && transactionType && saleStatus && cardBrand;
    });

    return {
      ...transaction,
      transactions: filtered,
    };
  });

  const amountByStatus = filteredTransactions?.reduce(
    (acc, transaction) => {
      if (transaction?.transactions) {
        transaction.transactions.forEach(
          ({
            transaction_status: tempTransactionStatus,
            total_value: totalValue,
          }) => {
            const status = transactionStatus[tempTransactionStatus];
            if (status) {
              acc[status] += totalValue;
            }
          },
        );
      }
      return acc;
    },
    {
      approved: 0,
      reversed: 0,
      pending: 0,
      denied: 0,
      canceled: 0,
      undone: 0,
    },
  );

  return {
    aprovadas: amountByStatus?.approved || 0,
    estornadas: amountByStatus?.reversed || 0,
    pendentes: amountByStatus?.pending || 0,
    negadas: amountByStatus?.denied || 0,
    canceladas: amountByStatus?.canceled || 0,
    desfeitas: amountByStatus?.undone || 0,
    transacoes:
      (filteredTransactions?.filter(
        (transaction) => transaction.transactions.length > 0,
      ) as ITransaction[]) || [],
  };
};

export function useSales({
  guidCreditCardCompany,
  transactionType,
  status,
}: IUseSalesProps = {}) {
  const [state] = useFilters();
  const { currentAccountId } = useAccountStore();

  const [, forceUpdate] = useState(0);

  useEffect(() => {
    forceUpdate((prev) => prev + 1);
  }, []);

  const querySalesListParams = {
    guidAccount: currentAccountId,
    startDate: formatDateToApi(state.startDate),
    endDate: formatDateToApi(state.endDate),
    guidCreditCardCompany,
    transactionType,
    status,
  };

  const queryBaseOptions = querySalesList(querySalesListParams);

  const queryFormatters = {
    select(data) {
      const { search, selectedTransactionType, selectedSaleStatus, cardBrand } =
        state;

      const hasSearch =
        search ||
        selectedTransactionType.length ||
        selectedSaleStatus.length ||
        cardBrand;

      if (data) {
        if (hasSearch) {
          return filterTransactions(data.transacoes, state);
        }

        return {
          aprovadas: data.aprovadas,
          estornadas: data.estornadas,
          pendentes: data.pendentes,
          negadas: data.negadas,
          canceladas: data.canceladas,
          desfeitas: data.desfeitas,
          transacoes: [],
        };
      }

      return null;
    },
  };

  const query = useQuery({
    ...queryBaseOptions,
    ...queryFormatters,
  });

  return { query, key: queryBaseOptions.queryKey };
}
