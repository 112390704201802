import styled from "styled-components";

export const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  height: "100vh",
  width: "100vw",

  "& div": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

export const Title = styled.h1((props) => ({
  // fontFamily: props.theme.fonts.base,
  color: props.theme.palette.primary.main,
}));

export const Description = styled.p((props) => ({
  fontSize: props.theme.fontSizes.xs,
  color: props.theme.palette.neutral.dark,
}));
