import { Fragment } from "react";
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { getSalesRoutesData, Hooks } from "@hyperlocal/banking-utility";
import { Input } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "../../../../components";
import { useDisclosure } from "../../../../hooks";
import {
  capitalizeOperation,
  cardBrandIcon,
  iconName,
} from "../../../../utils";
import { transactionStatus, useSales } from "../../api";
import { ISalesListItem } from "../../api/getSales/types";
import { useFilters } from "../../stores";
import { DetailsDrawer } from "../DetailsDrawer";
import { ExportFilesModal } from "../ExportFilesModal";
import { Card } from "./Card";
import * as Styles from "./Display.styles";

export function Display() {
  const navigate = useNavigate();
  const isMobile = Hooks.useMediaQuery("mobile");
  const searchDisclosure = useDisclosure();
  const detailsDisclosure = useDisclosure<{
    transaction: ISalesListItem;
  }>();

  const [state, send] = useFilters();

  const {
    query: { data },
  } = useSales();

  const dateFormatter = new Intl.DateTimeFormat("pt-BR", {
    day: "numeric",
    month: "long",
  });

  function getToday(date: string) {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);

    if (!date) {
      return dateFormatter.format(new Date());
    }
    const isToday = newDate.toDateString() === new Date().toDateString();

    if (isToday) {
      return `Hoje ${dateFormatter.format(newDate)}`;
    }

    return dateFormatter.format(newDate);
  }

  function parseAmountToBRL(amount = 0) {
    return amount.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

  const parseTransactionStatus = (status: string) => {
    return transactionStatus[status] || "approved";
  };

  return (
    <>
      <Styles.Container>
        <Styles.CardsContainer>
          <Styles.CardsWrapper>
            <Card
              type="pending"
              icon="MoneyMoneyRecive"
              amount={data?.pendentes}
            />
            <Tooltip
              label={
                <>
                  <p>Negadas: {parseAmountToBRL(data?.negadas)}</p>
                  <p>Canceladas: {parseAmountToBRL(data?.canceladas)}</p>
                  <p>Desfeitas: {parseAmountToBRL(data?.desfeitas)}</p>
                </>
              }
            >
              <Card
                type="reversed"
                icon="MoneyMoneyCanceled"
                amount={data?.estornadas as number}
              />
            </Tooltip>
            <Card
              type="approved"
              icon="MoneyMoneyRecive"
              amount={data?.aprovadas as number}
            />
          </Styles.CardsWrapper>
        </Styles.CardsContainer>

        <Styles.Content>
          <Styles.ListContainer>
            {isMobile ? (
              <>
                <Styles.FiltersRow>
                  <div>
                    <Styles.Button
                      leftIcon={{
                        name: "FilterFilter",
                      }}
                      onClick={() =>
                        navigate(getSalesRoutesData().salesReportFilters)
                      }
                    >
                      Filtros
                    </Styles.Button>
                    <Styles.SelectedFilterButton
                      active
                      data-visible={!!state.selectedFilter}
                      rightIcon={{
                        name: "SettingCheck",
                        onClick: undefined,
                      }}
                    >
                      {state.selectedFilter} dias
                    </Styles.SelectedFilterButton>
                  </div>

                  <ExportFilesModal
                    modalTitle="Exportar vendas"
                    data={data?.transacoes}
                  >
                    <div>
                      <button type="button">
                        <Icon name="ArrowExportRectangle" />
                      </button>
                      <button type="button" onClick={searchDisclosure.toggle}>
                        <Icon name="SearchNormal" />
                      </button>
                    </div>
                  </ExportFilesModal>
                </Styles.FiltersRow>

                {searchDisclosure.isOpen && (
                  <Input
                    data-visible={searchDisclosure.isOpen}
                    type="search"
                    placeholder="Pesquisar"
                    value={state.search}
                    onChange={(e) =>
                      send({
                        type: "SET_SEARCH",
                        payload: {
                          search: e.target.value,
                        },
                      })
                    }
                  />
                )}
              </>
            ) : (
              <ExportFilesModal
                modalTitle="Exportar vendas"
                data={data?.transacoes}
              />
            )}

            <Styles.List>
              {data?.transacoes?.map((transaction, index) => (
                <Fragment key={index}>
                  <Styles.ListHeadings>
                    <h5>{getToday(transaction?.date)}</h5>
                    <p>Vendas no dia {parseAmountToBRL(transaction?.amount)}</p>
                  </Styles.ListHeadings>

                  {transaction?.transactions?.map((el, i) => {
                    return (
                      <Styles.ListItem key={i}>
                        <button
                          type="button"
                          onClick={() => {
                            detailsDisclosure.open({
                              transaction: el,
                            });
                          }}
                        >
                          <Styles.Avatar
                            type={parseTransactionStatus(el.transaction_status)}
                            icon={iconName(el.transaction_status)}
                            size="md"
                          />
                          <Styles.ListItemInfo>
                            <div>
                              <p>
                                {capitalizeOperation(
                                  el.transaction_type,
                                  el?.credit_card_company,
                                  el?.transaction_status,
                                )}
                              </p>
                              <div>
                                <Icon
                                  name={cardBrandIcon(el.credit_card_company)}
                                />
                                <p>{el.acquirer_nsu}</p>
                              </div>
                            </div>
                            <div>
                              <p>
                                {el.total_installments > 1
                                  ? `Parcelado em ${el.total_installments}x`
                                  : `à Vista`}
                              </p>
                              <p>{parseAmountToBRL(el.total_value)}</p>
                            </div>
                          </Styles.ListItemInfo>
                        </button>
                      </Styles.ListItem>
                    );
                  })}
                </Fragment>
              ))}

              {data?.transacoes && (
                <>
                  {Object.keys(data.transacoes).length ? (
                    <Styles.SaleNotFound>
                      <p>
                        Não há mais nada por aqui... <br />
                        Para consultar datas anteriores use o filtro.
                      </p>
                    </Styles.SaleNotFound>
                  ) : (
                    <Styles.SaleNotFound>
                      <p>Nenhuma venda encontrada</p>
                    </Styles.SaleNotFound>
                  )}
                </>
              )}
            </Styles.List>
          </Styles.ListContainer>
        </Styles.Content>
      </Styles.Container>

      <DetailsDrawer
        isOpen={detailsDisclosure.isOpen}
        onClose={detailsDisclosure.close}
        content={detailsDisclosure?.modalProps?.transaction}
      />
    </>
  );
}
