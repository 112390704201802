import { IconProps } from "@hyperlocal/vital-icons";
import moment from "moment";
import Numeral from "numeral";
import "numeral/locales/pt-br";

export * from "./formatDateToApi";
export * from "./parseCardBrandIcon";
export * from "./lazyImport";

Numeral.locale("pt-br");

export const formatDate = (date: Date) => {
  if (!date) return;
  const newDate = new Date(date);
  const year = new Intl.DateTimeFormat("pt-br", {
    timeZone: "UTC",
    year: "numeric",
  }).format(newDate);
  const month = new Intl.DateTimeFormat("pt-br", {
    timeZone: "UTC",
    month: "long",
  }).format(newDate);
  const day = new Intl.DateTimeFormat("pt-br", {
    timeZone: "UTC",
    day: "2-digit",
  }).format(newDate);
  return `${day}/${month}/${year}`;
};
export const formatDateSearch = (date: Date | string) => {
  const newDate = new Date(date);
  const year = new Intl.DateTimeFormat("pt-br", {
    timeZone: "UTC",
    year: "numeric",
  }).format(newDate);
  const month = new Intl.DateTimeFormat("pt-br", {
    timeZone: "UTC",
    month: "2-digit",
  }).format(newDate);
  const day = new Intl.DateTimeFormat("pt-br", {
    timeZone: "UTC",
    day: "2-digit",
  }).format(newDate);
  return `${day}/${month}/${year}`;
};
export const formatDateMonthDay = (date: Date | string) => {
  const newDate = new Date(date);

  const month = new Intl.DateTimeFormat("pt-br", {
    timeZone: "UTC",
    month: "long",
  }).format(newDate);
  const day = newDate.getUTCDate();
  return `${day} de ${month} `;
};

export const formatHours = (date: Date) => {
  const newDate = new Date(date);
  const minutes =
    newDate.getMinutes() < 10
      ? "0" + newDate.getMinutes()
      : newDate.getMinutes();
  const hour = newDate.getHours();
  return `${hour}:${minutes}`;
};
export const formatToReal = (value: number, withoutCode?: boolean) => {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  if (withoutCode) {
    return formatter.format(value).replace("R$", "").trim();
  }
  return formatter.format(value);
};

export const formatWeekDay = (date: Date) => {
  const weekday = date.getDay();

  enum DAYS {
    "segunda-feira",
    "terça-feira",
    "quarta-feira",
    "quinta-feira",
    "sexta-feira",
    "sábado",
    "domingo",
  }
  return `${DAYS[weekday]}`;
};

export function extractOFX(extratoData, startDate, endDate, account) {
  const dateTimeStart = moment(moment(startDate).format("YYYY-MM-DD")).format(
    "YYYYMMDDHHmmss",
  );
  const dateTimeEnd = moment(moment(endDate).format("YYYY-MM-DD")).format(
    "YYYYMMDD235959",
  );
  const dateTimeNow = moment().format("YYYYMMDDHHmmss");

  let xmltext = `<OFX>
    <SIGNONMSGSRSV1>
        <SONRS>
            <STATUS>
                <CODE>0</CODE>
                <SEVERITY>INFO</SEVERITY>
            </STATUS> 
            <DTSERVER>${dateTimeNow}</DTSERVER>
            <LANGUAGE>POR</LANGUAGE>
            <FI>
                <ORG>WOW</ORG>
                <FID>WOW</FID>
            </FI>
        </SONRS>
    </SIGNONMSGSRSV1>
    <BANKMSGSRSV1>
        <STMTTRNRS>
            <TRNUID>1</TRNUID>
            <STATUS>
                <CODE>0</CODE>
                <SEVERITY>INFO</SEVERITY>
            </STATUS>
            <STMTRS>
                <CURDEF>BRL</CURDEF>
                <BANKACCTFROM>
                    <BANKID>479</BANKID>
                    <ACCTID>${account}</ACCTID>
                    <ACCTTYPE>CHECKING</ACCTTYPE>
                </BANKACCTFROM>
                <BANKTRANLIST>
                    <DTSTART>${dateTimeStart}</DTSTART>
                    <DTEND>${dateTimeEnd}</DTEND>`;

  extratoData.forEach((value) => {
    xmltext += `                   
                    <STMTTRN>
                        <TRNTYPE>OTHER</TRNTYPE>
                        <DTPOSTED>${moment(value.date).format(
                          "YYYYMMDDHHmmss",
                        )}</DTPOSTED>
                        <TRNAMT>${Numeral(value.amount).format("0.00")}</TRNAMT>
                        <PAYEEID>0</PAYEEID>
                        <MEMO>${value.summary}</MEMO>
                    </STMTTRN>`;
  });

  xmltext += `               
                </BANKTRANLIST>
                <LEDGERBAL>
                    <BALAMT>0.00</BALAMT>
                    <DTASOF>${dateTimeNow}</DTASOF>
                </LEDGERBAL>
            </STMTRS>
        </STMTTRNRS>
    </BANKMSGSRSV1>
</OFX>`;

  return { data: xmltext };
}

export const iconName = (type: string): IconProps["name"] => {
  switch (type) {
    case "NEGADO":
      return "MoneyMoneyCanceled";
    case "ESTORNADO":
      return "MoneyMoneyCanceled";
    default:
      return "MoneyMoneyRecive";
  }
};

export const cardBrandIcon = (brand: string): IconProps["name"] => {
  switch (brand) {
    case "MASTERCARD":
      return "CompaniesCompaniesCardMasterColored";
    case "VISA":
    case "VISA ELECTRON":
      return "CompaniesCompaniesCardVisaColored";
    case "ELO CREDITO":
    case "ELO DEBITO":
      return "CompaniesCompaniesCardEloColored";
    case "AMEX":
    case "AMEX CREDITO":
      return "CompaniesCompaniesCardAmericanExpressColored";
    case "CABAL CREDITO":
      return "CompaniesCompaniesCardCabalColored";
    case "HIPERCARD":
      return "CompaniesCompaniesCardHipercardColored";
    case "MAESTRO":
      return "CompaniesCompaniesCardMaestroColored";
    case "HIPER":
      return "CompaniesCompaniesCardHiperColored";
    case "SOROCRED":
      return "CompaniesCompaniesCardSorocredColored";
    case "DINERSCLUB":
      return "CompaniesCompaniesCardDinersColored";
    case "DISCOVER":
      return "CompaniesCompaniesCardDiscoverColored";
    case "AURA":
      return "CompaniesCompaniesCardAuraColored";
    case "JCB":
      return "CompaniesCompaniesCardJcbColored";
    default:
      return "MoneyPix";
  }
};

export const cardBrandGuid = (brand: string) => {
  switch (brand) {
    case "CREDSYSTEM":
      return "0E012CCB-DF53-4246-8BEE-0647AC7839BE";
    case "BANESCARD":
      return "42B573F7-CF98-458B-8AA1-089D7AE6ACE7";
    case "AMEX CREDITO":
      return "6D474A3E-4D4D-46A9-B27C-0CBD5B1E4E28";
    case "HIPERCARD":
      return "3E841321-48D0-44E8-98E3-2EAF4C4E3060";
    case "DISCOVER":
      return "5B9E9B24-A6D1-4BB5-9161-31A52713FD14";
    case "CREDZ":
      return "45F49121-E289-4989-9EC6-33320632FA37";
    case "DESCONHECIDO":
      return "7FC4F1CC-3404-457E-8043-52F1E975EDA9";
    case "ENROUTE":
      return "AFDB533C-9390-4CDE-B4A9-61F8E687D574";
    case "MASTERCARD":
      return "42357CD0-D706-4E54-93B2-6F63D1395E2B";
    case "WOW":
      return "8C1EF653-D87A-483F-A7FF-72CE42806D3F";
    case "HIPER":
      return "609F7C48-3020-41B1-8E67-7963F37AE8C6";
    case "VISA ELECTRON":
      return "C48E8FD9-CB11-422E-9EA6-7F9E48D67A6A";
    case "AMEX":
      return "D99DA009-814F-4C45-AFD7-81B3DBF388D6";
    case "CABAL DEBITO":
      return "D0012892-D5F0-49A5-9629-8476144411E4";
    case "SOROCRED":
      return "1126171A-B0F4-4AA1-9724-8CA46FE50CFD";
    case "JCB":
      return "3F9E47EE-F0F6-4487-8825-99D830B6D0FB";
    case "CABAL CREDITO":
      return "2A8CD619-2335-44C2-B184-A4FD37A019A0";
    case "DINERS":
      return "CD0C1B6C-0CE5-4884-B80D-A588439948B4";
    case "ELO DEBITO":
      return "ACCEC8FE-598A-4A02-A348-BD0E3DA2524B";
    case "VISA":
      return "04EE5FFF-4242-49C1-8836-BD187B188204";
    case "AURA":
      return "6A0E0DEA-BC74-45F5-A0AC-DB404B6FB59D";
    case "MAESTRO":
      return "C9DEDD99-3AC7-4B29-8E19-E94043FF3362";
    case "VOYAGER":
      return "40CBD607-7B6B-4B90-AD2E-EBEA29A954FF";
    case "ELO CREDITO":
      return "AD7DA1C6-DF8C-4E17-9BF5-FF7A1DE8D9CE";
    default:
      return "7FC4F1CC-3404-457E-8043-52F1E975EDA9";
  }
};

export const capitalizeOperation = (
  transactionType: string,
  creditCardCompany: string,
  transactionStatus: string,
): string => {
  if (transactionStatus === "NEGADO") return "Venda Cancelada";
  switch (transactionType) {
    case "PARCELAMENTO SEM JUROS":
    case "CREDITO A VISTA":
      return "Cartão de Crédito";
    case "DEBITO":
      return creditCardCompany === "PIX"
        ? "Pix na Maquininha"
        : "Cartão de Débito";
  }
};

export function formatTransactionType(value: string) {
  switch (value) {
    case "CREDITO A VISTA":
      return "Crédito à vista";
    case "PARCELAMENTO SEM JUROS":
      return "Crédito parcelado";
    case "DEBITO":
      return "Débito à vista";
    default:
      return value;
  }
}

export function capitalizeFirstLetter(value: string) {
  if (!value) return value;
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function formatPlataform(value: string) {
  switch (value) {
    case "E_COMMERCE":
      return "E-commerce";
    case "POS":
      return "Máquina de Cartão";
    default:
      return value;
  }
}

export function truncateCardNumber(value: string) {
  if (value) return value.slice(-4);
}
