import styled from "styled-components";
import { BREAKPOINTS } from "../../config";

export const Container = styled.div({
  minHeight: "100vh",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  [BREAKPOINTS.mobile]: {
    height: "100%",
    width: "100%",
  },
});
