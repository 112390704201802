import styled from "styled-components";
import { BREAKPOINTS } from "../../../../config";

export const Container = styled.section((props) => ({
  display: "flex",
  flexDirection: "column",

  padding: props.theme.spacings.inset.lg,

  background: props.theme.palette.neutral.white,

  border: `1px solid ${props.theme.palette.neutral.white}`,
  borderRadius: props.theme.borderRadius.sm,

  h4: {
    fontFamily: props.theme.fonts.base,
    fontSize: props.theme.fontSizes.xs,
    fontWeight: props.theme.fontWeights.bold,
    lineHeight: props.theme.lineHeights.md,
    color: props.theme.palette.neutral.darkest,
    alignSelf: "flex-start",

    [BREAKPOINTS.desktop]: {
      display: "none",
    },
  },

  [BREAKPOINTS.mobile]: {
    width: "100%",
    padding: props.theme.spacings.inset.md,
    minWidth: "unset",

    minHeight: "calc(100vh - 56px)",
    gap: props.theme.spacings.inline.xs,
  },
}));

export const FiltersBody = styled.div((props) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: props.theme.spacings.stack.x3s,
}));

export const ChipsContainer = styled.div((props) => ({
  width: "100%",

  display: "flex",
  gap: props.theme.spacings.inline.x2s,

  maxWidth: "100vw",
  whiteSpace: "nowrap",

  "::-webkit-scrollbar": {
    display: "none",
  },

  [BREAKPOINTS.mobile]: {
    overflowX: "auto",
  },
}));

export const FiltersContainer = styled.div((props) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 8,

  ".customPeriodWrapper": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    button: {
      padding: 0,
    },
  },

  "& h5": {
    fontFamily: props.theme.fonts.base,
    fontSize: props.theme.fontSizes.sm,
    fontWeight: props.theme.fontWeights.bold,
    lineHeight: props.theme.lineHeights.md,
    color: props.theme.palette.neutral.darkest,

    [BREAKPOINTS.mobile]: {
      fontSize: props.theme.fontSizes.xs,
    },
  },

  "& input": {
    boxSizing: "content-box",
  },
}));

export const DateInputsContainer = styled.div((props) => ({
  width: "100%",

  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",

  justifyContent: "flex-start",

  gap: props.theme.spacings.stack.xs,

  input: {
    boxSizing: "content-box",
    minWidth: 0,
  },

  [BREAKPOINTS.mobile]: {
    gap: props.theme.spacings.inline.x2s,

    "& input": {
      boxSizing: "border-box",
      height: "auto",
      width: "100%",
    },
  },
}));

export const ButtonContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",

  height: "100%",
  width: "100%",

  paddingTop: 82,

  [BREAKPOINTS.mobile]: {
    minHeight: "auto",

    paddingTop: "unset",
    justifyContent: "center",

    "& button": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 48,
      width: "100%",
      maxWidth: 343,
    },
  },
});

export const SaleStatusesContainer = styled.div((props) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: props.theme.spacings.inset.md,
  [BREAKPOINTS.mobile]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
}));

export const TransactionTypesContainer = styled.div((props) => ({
  display: "flex",
  gap: props.theme.spacings.inset.md,
}));
