import { IconProps } from "@hyperlocal/vital-icons";
import * as Styles from "./Display.styles";

const titles = {
  approved: "Aprovados",
  rejected: "Estornadas",
  pending: "Pendentes",
  reversed: "Estornadas",
  canceled: "Canceladas",
};

export type ICardTypes = keyof typeof titles;

export interface ICardProps {
  type: ICardTypes;
  icon: IconProps["name"];
  amount: number;
}

export function Card({
  type = "pending",
  icon = "MoneyMoneyRecive",
  amount = 0,
}: ICardProps) {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const [currency, amountValue] = formatter.format(amount).split(/\u00a0/);
  return (
    <Styles.Card>
      <div>
        <Styles.Avatar type={type} icon={icon} />
        <p>{titles[type]}</p>
      </div>
      <div>
        <span>{currency}</span>
        <h6>{amountValue}</h6>
      </div>
    </Styles.Card>
  );
}
