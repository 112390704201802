import { ReactNode } from "react";
import * as Popover from "@radix-ui/react-popover";
import * as Styles from "./Tooltip.styles";

type TooltipProps = {
  label: ReactNode;
  children: ReactNode;
};

export function Tooltip({ children, label }: TooltipProps) {
  return (
    <Popover.Root>
      <Popover.Trigger>{children}</Popover.Trigger>
      <Popover.Portal>
        <Popover.Content sideOffset={10}>
          <Styles.Tooltip>
            <div>{label}</div>
          </Styles.Tooltip>
          <Popover.Close />
          <Styles.Arrow />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
