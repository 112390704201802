import React from "react";
import { Button, Dialog } from "@hyperlocal/vital2";
import { WrapperContent } from "../ExportFilesModal.styles";

export function DestkopView({ children, modalTitle, isExportingData }) {
  const [isOpenDesktopView, setIsOpenDesktopView] = React.useState(false);

  const handleOpenExportFilesModal = () => {
    setIsOpenDesktopView(true);
  };

  const handleCloseExportFilesModal = () => {
    if (isExportingData) return;

    setIsOpenDesktopView(false);
  };

  return (
    <WrapperContent>
      <Button.Root
        className="border-primary-main text-primary-main button-export-wildcard export-file-button mb-4 border text-xs leading-none"
        variant="secondary"
        onClick={handleOpenExportFilesModal}
      >
        <Button.Slot name="GeralShareExport" className="size-[24px]" />
        Exportar
      </Button.Root>

      <Dialog.Root
        open={isOpenDesktopView}
        onOpenChange={handleCloseExportFilesModal}
      >
        <Dialog.Content className="max-w-[370px] gap-0">
          <Dialog.Header>
            <Dialog.Title>{modalTitle}</Dialog.Title>
          </Dialog.Header>

          {children}
        </Dialog.Content>
      </Dialog.Root>
    </WrapperContent>
  );
}
