import { create } from "zustand";
import { redux } from "zustand/middleware";
import { FILTERS, SALESTATUSES, TRANSACTIONTYPES } from "../../../config";

type IFilters = (typeof FILTERS)[number];
type ISalesStatuses = (typeof SALESTATUSES)[number];
type ITransactionType = (typeof TRANSACTIONTYPES)[number];

export interface IState {
  startDate: Date;
  endDate: Date;
  search: string;
  filters: IFilters[];
  selectedFilter: IFilters;
  selectedSaleStatus: ISalesStatuses[];
  selectedTransactionType: ITransactionType[];
  cardBrand: string;
  dateErrors: {
    startDate?: string;
    endDate?: string;
  };
}

type ActionType =
  | { type: "SET_START_DATE"; payload: { startDate: string } }
  | { type: "SET_END_DATE"; payload: { endDate: string } }
  | {
      type: "SET_START_AND_END_DATE";
      payload: { startDate: Date | string; endDate: Date | string };
    }
  | { type: "SET_SEARCH"; payload: { search: string } }
  | { type: "SET_SELECTED_FILTER"; payload: { selectedFilter: IFilters } }
  | {
      type: "SET_DATE_ERRORS";
      payload: { dateErrors: { startDate?: string; endDate?: string } };
    }
  | {
      type: "SET_SELECTED_SALE_STATUS";
      payload: { selectedSaleStatus: ISalesStatuses[] };
    }
  | {
      type: "SET_TRANSACTION_TYPE";
      payload: { selectedTransactionType: ITransactionType[] };
    }
  | { type: "SET_CARD_BRAND"; payload: { cardBrand: string } }
  | { type: "CLEAR_FILTERS" };

const TODAY = new Date();

export function initialStartDate() {
  const date = new Date(TODAY);
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() - 7);

  return date;
}

export function initialEndDate() {
  const date = new Date(TODAY);
  date.setHours(0, 0, 0, 0);
  return date;
}

function handleNormalizeDate(date: Date | string) {
  const parsedDate = new Date(date);
  parsedDate.setHours(0, 0, 0, 0);
  parsedDate.setDate(parsedDate.getDate() + 1);
  return parsedDate;
}

function setStartDate(state: IState, startDate: Date | string) {
  if (!state.startDate) return state;
  if (state.dateErrors.startDate) {
    return {
      ...state,
      dateErrors: {
        startDate: undefined,
        endDate: state.dateErrors.endDate,
      },
    };
  }

  const newStartDate = handleNormalizeDate(startDate);
  const ninetyDaysBeforeEndDate = new Date(state.endDate);
  ninetyDaysBeforeEndDate.setDate(state.endDate.getDate() - 90);

  if (newStartDate < ninetyDaysBeforeEndDate) {
    return {
      ...state,
      dateErrors: {
        startDate:
          "Máximo de 90 dias entre a data de início e a data de término",
        endDate: undefined,
      },
    };
  }

  if (newStartDate > state.endDate) {
    return {
      ...state,
      dateErrors: {
        startDate: "A data de início deve ser anterior à data de término",
        endDate: undefined,
      },
    };
  }

  return {
    ...state,
    dateErrors: {
      startDate: undefined,
      endDate: undefined,
    },
    startDate: newStartDate,
  };
}

function setEndDate(state: IState, endDate: Date | string) {
  const newEndDate = handleNormalizeDate(endDate);

  const ninetyDaysAfterStartDate = new Date(state.startDate);
  ninetyDaysAfterStartDate.setDate(state.startDate.getDate() + 90);

  if (newEndDate > ninetyDaysAfterStartDate) {
    return {
      ...state,
      dateErrors: {
        startDate: undefined,
        endDate: "Máximo de 90 dias entre a data de início e a data de término",
      },
    };
  }

  if (newEndDate < state.startDate) {
    return {
      ...state,
      dateErrors: {
        startDate: undefined,
        endDate: "A data de término deve ser posterior à data de início",
      },
    };
  }

  return {
    ...state,
    dateErrors: {
      startDate: undefined,
      endDate: undefined,
    },
    endDate: newEndDate,
  };
}

function setStartAndEndDate(
  state: IState,
  startDate: Date | string,
  endDate: Date | string,
) {
  return {
    ...state,
    startDate: setStartDate(state, startDate).startDate,
    endDate: setEndDate(state, endDate).endDate,
  };
}

function setSearch(state: IState, search: string) {
  return { ...state, search: search.toLowerCase() };
}

function setSelectedFilter(state: IState, selectedFilter: IFilters) {
  const currentDate = new Date(TODAY);
  const newDate = new Date(TODAY).getDate() - Number(selectedFilter);
  currentDate.setDate(newDate);

  if (currentDate.getTime() !== state.startDate?.getTime()) {
    return {
      ...state,
      selectedFilter,
      startDate: currentDate,
      endDate: new Date(TODAY),
      dateErrors: {
        startDate: undefined,
        endDate: undefined,
      },
    };
  }
  return {
    ...state,
    selectedFilter,
    startDate: initialStartDate(),
    endDate: initialEndDate(),
    dateErrors: {
      startDate: undefined,
      endDate: undefined,
    },
  };
}

function setSelectedSaleStatus(
  state: IState,
  selectedSaleStatus: ISalesStatuses[],
) {
  const arr = new Set(selectedSaleStatus);
  return { ...state, selectedSaleStatus: [...arr] };
}

function setTransactionType(
  state: IState,
  selectedTransactionType: ITransactionType[],
) {
  const arr = new Set(selectedTransactionType);
  return { ...state, selectedTransactionType: [...arr] };
}

function setDateErrors(
  state: IState,
  dateErrors: { startDate?: string; endDate?: string },
) {
  return { ...state, dateErrors };
}

function setCardBrand(state: IState, cardBrand: string) {
  return { ...state, cardBrand };
}

export const initialState: IState = {
  startDate: initialStartDate(),
  endDate: initialEndDate(),
  search: "",
  filters: [...FILTERS],
  selectedFilter: undefined,
  selectedSaleStatus: [
    "Aprovadas",
    "Canceladas",
    "Desfeitas",
    "Estornadas",
    "Negadas",
    "Pendentes",
  ],
  selectedTransactionType: ["Crédito", "Débito", "Pix"],
  cardBrand: "",
  dateErrors: {
    startDate: "",
    endDate: "",
  },
};

function reducer(state: IState, action: ActionType): IState {
  switch (action.type) {
    case "SET_START_DATE":
      return setStartDate(state, action.payload.startDate);
    case "SET_END_DATE":
      return setEndDate(state, action.payload.endDate);
    case "SET_START_AND_END_DATE":
      return setStartAndEndDate(
        state,
        action.payload.startDate,
        action.payload.endDate,
      );
    case "SET_SEARCH":
      return setSearch(state, action.payload.search);
    case "SET_SELECTED_FILTER":
      return setSelectedFilter(state, action.payload.selectedFilter);
    case "SET_DATE_ERRORS":
      return setDateErrors(state, action.payload.dateErrors);
    case "SET_SELECTED_SALE_STATUS":
      return setSelectedSaleStatus(state, action.payload.selectedSaleStatus);
    case "SET_TRANSACTION_TYPE":
      return setTransactionType(state, action.payload.selectedTransactionType);
    case "SET_CARD_BRAND":
      return setCardBrand(state, action.payload.cardBrand);
    case "CLEAR_FILTERS":
      return initialState;
    default:
      return state;
  }
}

const useStore = create(redux(reducer, initialState));

export function useFilters() {
  const state = useStore((state) => state);
  const dispatch = useStore((state) => state.dispatch);

  return [state, dispatch] as const;
}
