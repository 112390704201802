/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Suspense, useEffect } from "react";
import {
  getDashboardRoutesData,
  getSalesRoutesData,
  Hooks,
  setHeader,
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { navigateToUrl } from "single-spa";
import { Display, Filters, Layout, SkeletonDisplay } from "../../components";
import { useFilters } from "../../stores";
import * as Styles from "./SalesStatement.styles";

export function SalesStatement() {
  const isDesktop = Hooks.useMediaQuery("desktop");
  const routes = getSalesRoutesData();
  const [, send] = useFilters();

  useEffect(() => {
    setHeader({
      leftIcon: {
        name: "ArrowArrowNoLineLeft",
        onClick: () => navigateToUrl(getDashboardRoutesData().dashboardPay),
      },
      title: "Relatório de Vendas",
    });

    return () => {
      send({ type: "CLEAR_FILTERS" });
      setHeader(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop, send]);

  const breadcrumb = [
    {
      name: "Pay",
      path: getDashboardRoutesData().dashboardPay,
    },
    {
      name: "Relatório de Vendas",
      path: routes.salesReport,
    },
  ];

  return (
    <Layout
      title="Relatório de Vendas"
      backTo={getDashboardRoutesData().dashboardPay}
      breadcrumb={breadcrumb}
    >
      <Styles.Container>
        {isDesktop ? <Filters /> : null}

        <Suspense fallback={<SkeletonDisplay />}>
          <Display />
        </Suspense>
      </Styles.Container>
    </Layout>
  );
}
