export const CARDBRANDS = [
  "CREDSYSTEM",
  "BANESCARD",
  "AMEX CREDITO",
  "HIPERCARD",
  "DISCOVER",
  "CREDZ",
  "DESCONHECIDO",
  "ENROUTE",
  "MASTERCARD",
  "WOW",
  "HIPER",
  "VISA ELECTRON",
  "AMEX",
  "CABAL DEBITO",
  "SOROCRED",
  "JCB",
  "CABAL CREDITO",
  "DINERS",
  "ELO DEBITO",
  "VISA",
  "AURA",
  "MAESTRO",
  "VOYAGER",
  "ELO CREDITO",
] as const;

export const SALESTATUSES = [
  "Aprovadas",
  "Negadas",
  "Estornadas",
  "Pendentes",
  "Canceladas",
  "Desfeitas",
] as const;

export const TRANSACTIONTYPES = ["Débito", "Crédito", "Pix"] as const;

export const FILTERS = ["7", "15", "30", "60", "90"] as const;

export const TODAY = new Date();

export const BREAKPOINTS = {
  mobile: "@media only screen and (max-width: 720px)",
  tablet: "@media only screen and (min-width: 721px) and (max-width: 1080px)",
  desktop: "@media only screen and (min-width: 1081px)",
};
