/* eslint-disable @typescript-eslint/ban-ts-comment */
import { PropsWithChildren } from "react";
// @ts-ignore
import { getSalesRoutesData } from "@hyperlocal/banking-utility";
import { BreadCrumbItem } from "@hyperlocal/vital";
import { Link, useNavigate } from "react-router-dom";
import { navigateToUrl } from "single-spa";
import * as Styles from "./Layout.styles";

type IBreadCrumbItem = {
  name: string;
  path: string;
};
interface ILayoutProps {
  title?: string;
  breadcrumb?: IBreadCrumbItem[];
  backTo?: string;
}

export function Layout({
  children,
  title,
  backTo,
  breadcrumb,
}: PropsWithChildren<ILayoutProps>) {
  const routes = getSalesRoutesData();
  const navigate = useNavigate();
  return (
    <Styles.Container>
      <Styles.Content>
        <Styles.Box>
          <Styles.Box>
            {breadcrumb && (
              <Styles.Breadcrumb
                onClickIcon={() => navigateToUrl(routes.dashboard)}
              >
                {breadcrumb?.map((item, index) => (
                  <BreadCrumbItem
                    onClick={() => navigate(item.path)}
                    key={index}
                  >
                    {item.name}
                  </BreadCrumbItem>
                ))}
              </Styles.Breadcrumb>
            )}
            {title && (
              <Styles.Title>
                {backTo && (
                  <Link to={backTo}>
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.475 17.125l-4.5-4.5a.822.822 0 01-.188-.288.935.935 0 010-.675.824.824 0 01.188-.287l4.5-4.5A.72.72 0 0114 6.65c.2 0 .375.075.525.225a.72.72 0 01.225.525.72.72 0 01-.225.525L10.45 12l4.075 4.075a.72.72 0 01.225.525.72.72 0 01-.225.525.72.72 0 01-.525.225.72.72 0 01-.525-.225z"
                        fill="currentColor"
                      />
                    </svg>
                  </Link>
                )}

                {title}
              </Styles.Title>
            )}
          </Styles.Box>
          {children}
        </Styles.Box>
      </Styles.Content>
    </Styles.Container>
  );
}
