import {
  forwardRef,
  ForwardRefRenderFunction,
  HtmlHTMLAttributes,
} from "react";
import { Skeleton } from "@hyperlocal/vital";
import * as Styles from "./Display.styles";

type SkeletonItemProps = HtmlHTMLAttributes<HTMLLIElement>;

const SkeletonItemBase: ForwardRefRenderFunction<
  HTMLLIElement,
  SkeletonItemProps
> = (props, ref) => {
  return (
    <Styles.ListItem {...props} ref={ref}>
      <button type="button">
        <Skeleton width="32px" height="32px" borderRadius="100%" />

        <Styles.ListItemInfo>
          <div style={{ marginLeft: "16px" }}>
            <p>
              <Skeleton width="150px" height="16px" />
            </p>
            <div>
              <Skeleton width="16px" height="16px" borderRadius="100%" />
              <Skeleton width="130px" height="16px" />
            </div>
          </div>
          <div>
            <p>
              <Skeleton width="150px" height="16px" />
            </p>
            <p>
              <Skeleton width="150px" height="16px" />
            </p>
          </div>
        </Styles.ListItemInfo>
      </button>
    </Styles.ListItem>
  );
};

export const SkeletonItem = forwardRef(SkeletonItemBase);
