// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getSalesRoutesData } from "@hyperlocal/banking-utility";
import { Route, Routes } from "react-router-dom";
import { lazyImport } from "../../../utils";

const { SalesStatement } = lazyImport(
  () => import("../pages"),
  "SalesStatement",
);
const { Details } = lazyImport(() => import("../components"), "Details");
const { Filters } = lazyImport(() => import("../components"), "Filters");

export function SalesRoutes() {
  const routes = getSalesRoutesData();
  return (
    <Routes>
      <Route path={routes.salesReport} element={<SalesStatement />} />
      <Route path={routes.salesReportFilters} element={<Filters />} />
      <Route path={routes.salesReportDetails} element={<Details />} />
    </Routes>
  );
}
