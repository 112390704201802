import * as Vital from "@hyperlocal/vital";
import styled from "styled-components";
import { BREAKPOINTS } from "../../../../config";

// Wrapper criado para sobreescrever estilos definidos diretamente na tag <button>

export const WrapperContent = styled.div(({ theme }) => ({
  button: {
    padding: "0 !important",
    height: theme.spacings.stack.xs,
    width: theme.spacings.stack.x3l,
    maxWidth: theme.spacings.stack.x3l,
  },

  main: {
    width: "370px",

    h2: {
      color: theme.palette.primary.main,
      fontSize: theme.fontSizes.xs,
      lineHeight: theme.lineHeights.md,
      fontWeight: theme.fontWeights.bold,
    },
  },
}));

export const DrawerWrapper = styled.div({
  position: "absolute",
  top: 0,

  "& .vital-card": {
    width: "100%",
    cursor: "pointer",

    "& > button": {
      cursor: "pointer",
    },
  },
});

export const ButtonContainer = styled.button((props) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",

  width: "100%",
  height: "100%",

  background: "transparent",
  border: "none",

  "& div": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  "& svg": {
    width: 20,
    height: 20,

    "& path": {
      fill: props.theme.palette.neutral.darkest,
    },
    "& g": {
      stroke: props.theme.palette.neutral.darkest,
    },
  },

  "& p": {
    marginLeft: props.theme.spacings.stack.nano,
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.regular,
    fontSize: props.theme.fontSizes.xs,
    lineHeight: props.theme.lineHeights.md,
    color: props.theme.palette.neutral.darkest,
  },

  ".iconWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    width: 48,
    height: 48,

    margin: 0,

    background: "transparent",
    border: "none",
  },
}));

export const Container = styled.div((props) => ({
  width: "100%",
  height: "100%",

  "& .vital-card": {
    width: "100%",
    cursor: "pointer",

    "& > button": {
      cursor: "pointer",
    },
  },

  "& > h5": {
    fontFamily: props.theme.fonts.base,
    fontWeight: props.theme.fontWeights.bold,
    fontSize: props.theme.fontSizes.sm,
    lineHeight: props.theme.lineHeights.md,
    color: props.theme.palette.neutral.darker,

    marginTop: props.theme.spacings.stack.x2s,
    marginBottom: props.theme.spacings.stack.x3s,
  },

  "& > div": {
    padding: `12px 0 12px ${props.theme.spacings.stack.x3s}`,
    height: "clamp(48px, 100%, 48px)",
    border: "2px solid #E5E5E5",
  },

  "& div + div": {
    marginTop: props.theme.spacings.stack.x3s,
  },
}));

export const Title = styled.h5((props) => ({
  fontFamily: props.theme.fonts.base,
  fontWeight: props.theme.fontWeights.bold,
  fontSize: props.theme.fontSizes.sm,
  lineHeight: props.theme.lineHeights.md,
  color: props.theme.palette.neutral.darker,

  marginTop: props.theme.spacings.stack.x2s,

  [BREAKPOINTS.mobile]: {
    fontSize: props.theme.fontSizes.x2s,
    fontWeight: props.theme.fontWeights.regular,
  },
}));

export const Card = styled(Vital.Card)({
  height: "clamp(48px, 100%, 48px)",
  width: "100%",
  [BREAKPOINTS.mobile]: {
    height: 48,
  },
});
